*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.workExp{
    display: block;
}

li{
    list-style: disc !important;
}

#expTitle{
    font-weight: 700;
    line-height: 1.25;
    font-size: 3rem;
    margin-bottom: .5rem;
    margin-left: -41rem;
}

#expDetails{
    margin-left: -10rem;
}

#workType{
    display: grid;
    grid-template-columns: 1fr;
}

#buttonTech{
    text-align: center;
}

.typeOfWork{
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 1rem;
    background-color: #f5f8fa;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.125);
    padding: 1rem;
    margin: 0.5rem;
}

.typeOfWork:focus{
    color: #0096cc;
    background-color: #f5f8fa;
    transition: 0.2s;
}


.displayExp{
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 1.75rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,.125);
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
}


#containsImage{
    border: none;
    border-radius: 0%;
    width: 200px;
    height: 200px;
}

#workRole{
    color: #0096cc;
}

#workDiv h3{
    font-family: open sans,sans-serif;
}

.workRes{
        display: block;
        text-align: left;
        color: #2e2e2e;
        letter-spacing: .01rem;
        line-height: 2rem;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 1rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    @media screen and (max-width: 767px) {


    .displayExp #containsImage{
        width:100%;

    }

    #workDiv{
        margin-left: 0.3rem;
    }

        section{
            padding: 1rem;   
        }
    
        main{
            padding-left: 16px;
            width: 100%;
        }
    
        #expTitle, #expDetails h3{
            font-weight: 700;
            line-height: 1.25;
            font-size: 2rem;
            margin-bottom: 0rem;
            margin-left: 0px;
            text-align: left;
        }
        
        #expDetails{
            margin-left: 0rem;
            text-align: left;
            font-size: 1rem;
            margin-left: 0rem;
    
        }
    

        .displayExp{
            display: grid;
            grid-template-columns: 1fr;
            overflow: hidden;
            padding-left: 16px;
            padding-right: 16px;
        }

        .workRes{
            margin-left: 20px;
        }

    }

    @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    section{
        padding: 2rem;   
    }

    main{
        padding: auto;
    }

    #expTitle, #expDetails h3{
        font-weight: 700;
        line-height: 1.25;
        font-size: 2rem;
        margin-bottom: 0rem;
        margin-left: 0px;
        text-align: left;
    }
    
    #expDetails{
        margin-left: 0rem;
        text-align: left;
        font-size: 1rem;
    }

}