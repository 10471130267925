@import url(https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap);
html,body{
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.aboutSection{
    display: block;
}
section{
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 1.75rem;
    background-color: #f5f8fa;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.125);
}

.contact{
    margin-left: auto; 
    margin-right: auto;}

img{
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.125);
    padding: 5px;
    border-radius: 50%;
    width: auto;
    height: 140px;

}

#imgProfile{
    background-color: #fff;
    animation: pulse 1s infinite;
}

h3{
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25;
    letter-spacing: 2px;
    font-family: open sans,sans-serif;
    color: #3a4145;
}

h2{
    line-height: 1.50;
    color: #aaa;
    letter-spacing: .05rem;
}

#intro2{
    line-height: 1.50;
    margin-top: 1em;
    margin-bottom: .5em;
    letter-spacing: .05rem;
    font-weight: 400;
}

small{
    font-size: 15px;
    color: #0096cc;
}

main{
    max-width: 70em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 2rem;
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .05rem;
    line-height: 2rem;
    text-align: left;
}

#details{
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.contact{
    display: grid;
    width: 25%;
}

.contact h3{
    padding-left: 1rem;
}

.socialMedia a{
    float: left;
    margin-bottom: -1rem;
}
.socialMedia a i{
    display: block;
    clear: both;
}
i span{
    padding-left: 1rem;
    font-size: 1rem;
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .05rem;
    line-height: 0.5rem;
}

.fa-facebook span{
    padding-left: 1.5rem;
}

.socialMedia a{
    text-decoration: none;
    color: #2e2e2e;
    font-family: 'Merriweather', serif;
    border: 1px solid transparent;
    padding: 1rem;
}

a{
    text-decoration: none;
    color: #0096cc;
}

.rainbow-text {
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

@keyframes pulse{
    from{
        box-shadow: 0 0 0 0 rgba(119, 194, 234);
    }
    to{
        box-shadow: 0 0 0 30px rgba(255, 122, 0, 0);
    }
}

@media screen and (max-width: 767px) {

    main{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .contact h3{
        padding: 1.3rem 0rem;
        padding-left: 0rem;

    }
    i span{
      display: none;
    }

    .socialMedia a{
        padding: 0.5rem 0rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }

    .about{
        padding-bottom: 1rem;
    }
    .contact{
        border-top: 1px solid transparent;
        border-color: rgba(0,0,0,.125);
        width: 100%;
    }

}
*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.projectTitle{
    display: block;
}

section{
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 1.75rem;
    background-color: #f5f8fa;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.125);
    padding: 3rem;
}

#projectId{
    display: grid;
    grid-template-columns: 1fr;
}
.project{
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 1.75rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,.125);
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: -0.2rem;
    margin-right: -1rem;
}

#containsImage{
    border: none;
    border-radius: 0%;
    width: 200px;
    height: 200px;
}

#containsLinks{
    text-decoration: none;
    color: #0096cc;

}

#projectDetails h3{
    font-family: open sans,sans-serif;
}

#projTitle{
    font-weight: 700;
    line-height: 1.25;
    font-size: 3rem;
    margin-bottom: .5rem;
    margin-left: -52rem;
}

#projDetails{
    margin-left: -34rem;
}

.typeOfTech{
    font-family: merriweather,serif;
    color: #2e2e2e;
    background-color: #f5f8fa;
    letter-spacing: .01rem;
    line-height: 0.5rem;
    background-color: #f5f8fa;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.125);
    padding: 0.5rem;
    margin: 0.1rem;
}

.typeOfTech:focus{
    color: #0096cc;
    background-color: #f5f8fa;
    transition: 0.2s;
}



.projDescription{
    display: block;
    text-align: left;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 2rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

@media screen and (max-width: 767px) {

    div #containsImage{
        width:100%;

    }

    section{
        padding: 1rem;   
    }

    main{
        padding: auto;
    }

    .project{
        margin-left: -1rem;
        margin-right: -0.5rem;
    }

    #projTitle, #projectDetails h3{
        font-weight: 700;
        line-height: 1.25;
        font-size: 2rem;
        margin-bottom: 0rem;
        margin-left: 0px;
        text-align: left;
    }
    
    #projDetails{
        margin-left: 0rem;
        text-align: left;
        font-size: 1rem;
        
    }


    .project{
        display: grid;
        grid-template-columns: 1fr;
        overflow: hidden;
        padding-left: 16px;
        padding-right: 16px;
    }

    #projectDetails{
        overflow: hidden;
    }

    #containsLinks{
        overflow: hidden;
        word-wrap: break-word;
    }

}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    section{
        padding: 2rem;   
    }

    main{
        padding: auto;
    }

    #projTitle, #projectDetails h3{
        font-weight: 700;
        line-height: 1.25;
        font-size: 2rem;
        margin-bottom: 0rem;
        margin-left: 0px;
        text-align: left;
    }
    
    #projDetails{
        margin-left: 0rem;
        text-align: left;
        font-size: 1rem;
        
    }

}
*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.skillSet{
    display: block;
}

#skillId{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

#skillTitle{
    font-weight: 700;
    line-height: 1.25;
    font-size: 3rem;
    margin-bottom: .5rem;
    margin-left: -56rem;
}

#skillDetails{
    margin-left: -27.5rem;
}

#eachSkill{
    font-family: merriweather,serif;
    color: #0096cc;
    background-color: #f5f8fa;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.125);
    padding: 1rem;
    margin: 0.5rem;
}

.web{
    text-align: center;
    padding-bottom: 2rem;
}

.web h3{
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: nowrap;
}

@media screen and (max-width: 767px) {

    section{
        padding: 1rem;   
    }

    main{
        padding-left: 16px;
        width: 100%;
    }

    #skillTitle, #skillDetails h3{
        font-weight: 700;
        line-height: 1.25;
        font-size: 2rem;
        margin-bottom: 0rem;
        margin-left: 0px;
        text-align: left;
    }
    
    #skillDetails{
        margin-left: 0rem;
        text-align: left;
        font-size: 1rem;
        margin-left: 0rem;

    }


    #skillId{
        display: grid;
        grid-template-columns: 1fr;
        padding-bottom: 1rem;
    }

    .web{
        padding-bottom: 1.5rem;
    }
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    section{
        padding: 2rem;   
    }

    main{
        padding: auto;
    }

    #skillTitle, #skillDetails h3{
        font-weight: 700;
        line-height: 1.25;
        font-size: 2rem;
        margin-bottom: 0rem;
        margin-left: 0px;
        text-align: left;
    }
    
    #skillDetails{
        margin-left: 0rem;
        text-align: left;
        font-size: 1rem;
    }
    .web{
        overflow: hidden;
        padding-left: 0rem;
        padding-right: 0rem;
        white-space: nowrap;
    }
    .web h3{
        overflow: hidden;
        padding-left: 0.3rem;
        padding-right: 0rem;
    }

}
*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.workExp{
    display: block;
}

li{
    list-style: disc !important;
}

#expTitle{
    font-weight: 700;
    line-height: 1.25;
    font-size: 3rem;
    margin-bottom: .5rem;
    margin-left: -41rem;
}

#expDetails{
    margin-left: -10rem;
}

#workType{
    display: grid;
    grid-template-columns: 1fr;
}

#buttonTech{
    text-align: center;
}

.typeOfWork{
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 1rem;
    background-color: #f5f8fa;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.125);
    padding: 1rem;
    margin: 0.5rem;
}

.typeOfWork:focus{
    color: #0096cc;
    background-color: #f5f8fa;
    transition: 0.2s;
}


.displayExp{
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 1.75rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,.125);
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
}


#containsImage{
    border: none;
    border-radius: 0%;
    width: 200px;
    height: 200px;
}

#workRole{
    color: #0096cc;
}

#workDiv h3{
    font-family: open sans,sans-serif;
}

.workRes{
        display: block;
        text-align: left;
        color: #2e2e2e;
        letter-spacing: .01rem;
        line-height: 2rem;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 1rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    @media screen and (max-width: 767px) {


    .displayExp #containsImage{
        width:100%;

    }

    #workDiv{
        margin-left: 0.3rem;
    }

        section{
            padding: 1rem;   
        }
    
        main{
            padding-left: 16px;
            width: 100%;
        }
    
        #expTitle, #expDetails h3{
            font-weight: 700;
            line-height: 1.25;
            font-size: 2rem;
            margin-bottom: 0rem;
            margin-left: 0px;
            text-align: left;
        }
        
        #expDetails{
            margin-left: 0rem;
            text-align: left;
            font-size: 1rem;
            margin-left: 0rem;
    
        }
    

        .displayExp{
            display: grid;
            grid-template-columns: 1fr;
            overflow: hidden;
            padding-left: 16px;
            padding-right: 16px;
        }

        .workRes{
            margin-left: 20px;
        }

    }

    @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    section{
        padding: 2rem;   
    }

    main{
        padding: auto;
    }

    #expTitle, #expDetails h3{
        font-weight: 700;
        line-height: 1.25;
        font-size: 2rem;
        margin-bottom: 0rem;
        margin-left: 0px;
        text-align: left;
    }
    
    #expDetails{
        margin-left: 0rem;
        text-align: left;
        font-size: 1rem;
    }

}

*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    
}

nav{
    display: flex;
    flex-wrap: wrap;
    line-height: 3rem;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    letter-spacing: 2px;
}

#nameLink{
    float: left;
    font-family: Montserrat,helvetica neue,Helvetica,Arial,sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 6px;
}

.item {
    position: relative;
    display: inline;
}

li {
    list-style: none;   
}

li a{
    text-decoration: none;
    color: #2e2e2e;
    font-family: 'Merriweather', serif;
    border: 1px solid transparent;
    padding: 1rem;
}
#nameLink a{
    text-decoration: none;
    color: #2e2e2e;
    font-family: 'Merriweather', serif;
    border: 1px solid transparent;
    padding: 1rem;
}

li a:focus{
    color: #0096cc;
    background-color: #f5f8fa;
    transition: 0.2s;
}



#nameLink a:hover{
    color: #0096cc;
    background-color: #f5f8fa;
    transition: 0.2s;
}

.hamburger{
    display: none;
    border: 0;
    cursor: pointer;
}

.hamburger:focus {
    outline: none;
}

@media screen and (max-width: 767px) {
    .nav-menu{
        display: none;
        flex-direction: column;
        width: 100%;
    }
    .nav-menu.active{
        display: flex;
    }

    .hamburger{
        display: block;
        margin: 15px 15px;
    }

}



footer{
    position:fixed;
    left: 0;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 100%;
    color: #aaa;
    background-color: #f5f8fa;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(0,0,0,.125);
    letter-spacing: .01rem;
    font-family: merriweather,serif;
    font-size: 0.9rem;
    text-align: left;
}
