
footer{
    position:fixed;
    left: 0;
    bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 100%;
    color: #aaa;
    background-color: #f5f8fa;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(0,0,0,.125);
    letter-spacing: .01rem;
    font-family: merriweather,serif;
    font-size: 0.9rem;
    text-align: left;
}