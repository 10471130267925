*{
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.projectTitle{
    display: block;
}

section{
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 1.75rem;
    background-color: #f5f8fa;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.125);
    padding: 3rem;
}

#projectId{
    display: grid;
    grid-template-columns: 1fr;
}
.project{
    display: grid;
    grid-template-columns: 1fr 3fr;
    font-family: merriweather,serif;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 1.75rem;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0,0,0,.125);
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: -0.2rem;
    margin-right: -1rem;
}

#containsImage{
    border: none;
    border-radius: 0%;
    width: 200px;
    height: 200px;
}

#containsLinks{
    text-decoration: none;
    color: #0096cc;

}

#projectDetails h3{
    font-family: open sans,sans-serif;
}

#projTitle{
    font-weight: 700;
    line-height: 1.25;
    font-size: 3rem;
    margin-bottom: .5rem;
    margin-left: -52rem;
}

#projDetails{
    margin-left: -34rem;
}

.typeOfTech{
    font-family: merriweather,serif;
    color: #2e2e2e;
    background-color: #f5f8fa;
    letter-spacing: .01rem;
    line-height: 0.5rem;
    background-color: #f5f8fa;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0,0,0,.125);
    padding: 0.5rem;
    margin: 0.1rem;
}

.typeOfTech:focus{
    color: #0096cc;
    background-color: #f5f8fa;
    transition: 0.2s;
}



.projDescription{
    display: block;
    text-align: left;
    color: #2e2e2e;
    letter-spacing: .01rem;
    line-height: 2rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

@media screen and (max-width: 767px) {

    div #containsImage{
        width:100%;

    }

    section{
        padding: 1rem;   
    }

    main{
        padding: auto;
    }

    .project{
        margin-left: -1rem;
        margin-right: -0.5rem;
    }

    #projTitle, #projectDetails h3{
        font-weight: 700;
        line-height: 1.25;
        font-size: 2rem;
        margin-bottom: 0rem;
        margin-left: 0px;
        text-align: left;
    }
    
    #projDetails{
        margin-left: 0rem;
        text-align: left;
        font-size: 1rem;
        
    }


    .project{
        display: grid;
        grid-template-columns: 1fr;
        overflow: hidden;
        padding-left: 16px;
        padding-right: 16px;
    }

    #projectDetails{
        overflow: hidden;
    }

    #containsLinks{
        overflow: hidden;
        word-wrap: break-word;
    }

}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    section{
        padding: 2rem;   
    }

    main{
        padding: auto;
    }

    #projTitle, #projectDetails h3{
        font-weight: 700;
        line-height: 1.25;
        font-size: 2rem;
        margin-bottom: 0rem;
        margin-left: 0px;
        text-align: left;
    }
    
    #projDetails{
        margin-left: 0rem;
        text-align: left;
        font-size: 1rem;
        
    }

}